* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.App {
	padding: 0;
	margin: 0;
}
header {
	background: linear-gradient(to right, #fe4880, #ffce00);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1rem;
}
header div {
	margin: 0 2rem 0 0;
}

header .name input {
	display: inline-block;
	color: #fff;
	font-size: 2rem;
	font-weight: 600;
	font-style: italic;
	text-transform: uppercase;
	background: none;
	border: none;
	outline: none;
	appearance: none;
}
header::before {
	display: inline-block;
	color: #fff;
	font-size: 2rem;
	font-weight: 600;
	font-style: italic;
	content: "/r/";
	text-transform: uppercase;
}
.articles article {
	padding: 2rem 1rem;
	background-color: #fff;
}
.articles article a {
	text-decoration: none;
}
.articles article a h3 {
	color: #133200;
}

.articles article:nth-child(2n) {
	background-color: #ddd;
}
.articles article:hover {
	background: linear-gradient(to left, #fe4880, #ffce00);
}
.articles article:hover a h3 {
	color: #000000;
	font-size: x-large;
}
.articles article a h3:hover {
	color: #500060;
}
